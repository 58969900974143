export const STATES = {
  RECORDING: "RECORDING",
  PAUSED: "PAUSED",
  STOPPED: "STOPPED",
};

export const mimeTypesWithExtension = {
    "audio/wav": ".wav",
    "audio/webm": ".webm",
    "audio/mp3": ".mp3",
    "audio/ogg": ".ogg",
    "audio/aac": ".aac",
  };