import { STATES } from "../constants";
import Button from "./Button";

const MESSAGES = {
  [STATES.RECORDING]: "Recording Started!",
  [STATES.PAUSED]: "Recording Paused!",
  [STATES.STOPPED]: "Recording Stopped!",
};

export default function RecordingAction({
  recordingStatus,
  stopRecording,
  startRecording,
  pauseRecording,
  resumeRecording,
}) {
  return (
    <>
      <h1 className="text-[#114DC8] text-3xl">{MESSAGES[recordingStatus]}</h1>
      <StartStopButton
        recordingStatus={recordingStatus}
        startRecording={startRecording}
        stopRecording={stopRecording}
      />
      <PausedResumeButton
        recordingStatus={recordingStatus}
        resumeRecording={resumeRecording}
        pauseRecording={pauseRecording}
      />
    </>
  );
}

const StartStopButton = ({
  recordingStatus,
  startRecording,
  stopRecording,
}) => {
  if (recordingStatus === STATES.STOPPED) {
    return <Button onClick={startRecording}>Start Recording!</Button>;
  } else {
    return <Button onClick={stopRecording}>Stop Recording!</Button>;
  }
};

const PausedResumeButton = ({
  recordingStatus,
  pauseRecording,
  resumeRecording,
}) => {
  if (recordingStatus === STATES.PAUSED) {
    return <Button onClick={resumeRecording}>Resume Recording!</Button>;
  } else {
    return (
      <Button
        disable={(recordingStatus !== STATES.RECORDING).toString()}
        onClick={pauseRecording}
        className={
          recordingStatus !== STATES.RECORDING
            ? "border-[grey] text-[gray] hover:opacity-100 pointer-events-none"
            : ""
        }
      >
        Pause Recording!
      </Button>
    );
  }
};
