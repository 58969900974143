import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Button from "./Button";
import RecordingAction from "./RecordingAction";
import Loader from "./Loader";
import { STATES, mimeTypesWithExtension } from "../constants";
import { toast } from "react-toastify";

const getSupportedMimeType = () => {
  return Object.keys(mimeTypesWithExtension).find((type) =>
    MediaRecorder.isTypeSupported(type)
  );
};

function getQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = {};

  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value;
  }

  return queryParams;
}

const sendAudio = async (formData, token) => {
  try {
    await axios.post(
      "https://api.scribesimple.com/scribe-simple-operation/",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTimeout(() => alert("Successful, You can start the new recording."), 500);
  } catch (error) {
    console.log(error);
    const errorMessage =
      error.response.data?.error ??
      error.response.data?.detail ??
      "Something went wrong, While sending audio!";
    alert(errorMessage);
  }
};

const saveToken = async (token) => {
  try {
    await axios.get(
      "https://api.scribesimple.com/scribe/token",
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return false;
  } catch (error) {
    const errorMessage =
      error.response.data?.error ??
      error.response.data?.detail ??
      "Something went wrong, Please try again!";
    return errorMessage;
  }
};

const generateFileName = (prefix, extension) => {
  // const date = new Date();
  // const now = date.toUTCString();
  return `${prefix}__${Date.now}${extension}`;
};

export default function Home() {
  const [permission, setPermission] = useState(false);
  const [mimeType, setMimeType] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState(STATES.STOPPED);
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audio, setAudio] = useState(null);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: { exact: false },
          },
          video: false,
        });
        setStream(streamData);
        return true;
      } catch (err) {
        console.log(err.message);
        if (
          err.message === "Permission denied" ||
          err.message === "Permission dismissed"
        ) {
          // alert(
          //   "Audio access has been denied. Please grant permission to use the Audio."
          // );
          return "DENIED";
        } else {
          alert(
            "Something went wrong, While getting the microphone permission!!"
          );
        }
        return false;
      }
    } else {
      alert(
        "The MediaRecorder API is not supported in your browser. Please connect the microphone with the device/pc"
      );
    }
    return false;
  };

  const startRecording = async () => {
    const params = getQueryParams();
    const isTokenNotSaved = await saveToken(params.token);

    if (isTokenNotSaved){
      alert(isTokenNotSaved);
      window.close();
      return;
    }

    setRecordingStatus(STATES.RECORDING);
    const supportedMimeType = getSupportedMimeType();

    setMimeType(supportedMimeType);
    const media = new MediaRecorder(stream, { type: supportedMimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);

    // Notify the user 30 minutes before stopping the recording
    setTimeout(() => {
      toast.info("Recording will stop in 30 minutes.")
    }, 2 * 60 * 60 * 1000); // 2 hours in milliseconds for testing set 5 * 1000
    
    // Stop the recording and close the window after 2 hours and 30 minutes
    setTimeout(() => {
      toast.info("2 hours and 30 minutes completed. Recording has been stopped.");
      stopRecording();
    }, 2.5 * 60 * 60 * 1000); // 2.5 hours in milliseconds for testing set 15 * 1000
  };

  const stopRecording = () => {
    setRecordingStatus(STATES.STOPPED);
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      setLoading(true);
      console.log(audioChunks);
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      const formData = new FormData();
      const params = getQueryParams();
      const fileName = generateFileName(
        params.patient_name ?? "rec",
        mimeTypesWithExtension[mimeType]
      );

      console.log(params);
      formData.append("file", audioBlob, fileName);
      formData.append("patient_name", params.patient_name ?? "None");
      formData.append("visit_type", params.visit_type);
      formData.append("pronouns", params.patient_pronouns);
      formData.append("specialization", params?.specialization ?? "");
      await sendAudio(formData, params.token);

      setAudio(audioUrl);
      setAudioChunks([]);
      setLoading(false);
      // window.close();
    };
  };

  const pauseRecording = () => {
    if (
      recordingStatus === STATES.RECORDING ||
      recordingStatus === STATES.RESUME
    ) {
      mediaRecorder.current.pause();
      setRecordingStatus(STATES.PAUSED);
    }
  };

  const resumeRecording = () => {
    if (recordingStatus === STATES.PAUSED) {
      mediaRecorder.current.resume();
      setRecordingStatus(STATES.RECORDING);
    }
  };

  useEffect(() => {
    (async () => {
      const permission = await getMicrophonePermission();
      setPermission(permission);
      setLoading(false);
    })();
  });

  return (
    <div className="flex place-content-center h-screen items-center bg-black">
      <div className="w-[30rem] h-fit flex flex-col gap-6 justify-center items-center">
        {isLoading ? (
          <Loader />
        ) : permission === "DENIED" || !permission ? (
          <>
            <Button onClick={getMicrophonePermission}>Give Permission</Button>
            {permission === "DENIED" && (
              <p className="text-[red] text-center">
                Audio access has been denied. You need to provide the permission
                manually.
              </p>
            )}
          </>
        ) : (
          <RecordingAction
            recordingStatus={recordingStatus}
            stopRecording={stopRecording}
            startRecording={startRecording}
            pauseRecording={pauseRecording}
            resumeRecording={resumeRecording}
          />
        )}

        {/* This is for downloading this audio file.. uncomment this to enable download */}

        {/* <div className="grid gap-3 place-content-center">
          <audio src={audio} controls></audio>
          <a download href={audio} className="text-primary">
            Download Recording
          </a>
        </div> */}
      </div>
    </div>
  );
}
