export default function Button({ className, children, ...props }) {
  return (
    <button
      className={`text-primary hover:opacity-70 font-bold py-4 px-6 rounded border border-primary ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}
